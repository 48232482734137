.activities-container {
  padding: 1.5rem;
}

.activities-title {
  font-size: 4rem;
  margin-bottom: 2.5rem;
}

*:focus {
  outline: none !important;
}