.text-danger {
  color: red;
}

/* Hide the input...if filled out, then it is a bot */
.hidden_honeypot {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.phone-input {
  border: 1px solid #ccc;
}

.input-before::before {
  content: "+ \00a0";
  /* border: 1px black solid; */
}

/* @media only screen and (max-width: 600px) {

} */