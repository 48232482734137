.user-connections-title {
  font-size: 3.5rem;
}

ion-card.connections {
  width: 50vw;
}

@media only screen and (max-width: 600px) {
  .user-connections-title {
    font-size: 2rem;
  }
  .user-connections-no-or-fail {
    font-size: 1.5rem;
  }
  ion-card.connections {
    width: 85vw;
  }
}

.shiner {
  background-color: gold;
}

.shiner::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent 25%,
    rgba(255, 255, 255, 0.8) 35%,
    rgba(255, 255, 255, 0.8) 65%,
    transparent 75%
  );
  animation: shiner 4s infinite linear;
}

@keyframes shiner {
  0% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(150%);
  }
}