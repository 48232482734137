.title {
    font-weight: bold;
    font-size: xx-large;
}

ion-alert.custom-alert {
    --backdrop-opacity: 0.8;
}
  
/* xs */
@media only screen and (min-width: 600px) {
    .container {
       margin-left: 2rem;
    }
    .button-section {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: .1rem;
        gap: 0.5rem;
    }
}


