.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1000;
}

ion-item.chat {
  --background: white;
}

.chat-list-background {
  background-color: white;
}

input[type=text] {
  border: none;
  padding: 0.5rem;
  width: 100%;
  box-sizing: border-box;
}

input[type=text]:focus {
  border-radius: 5px;
}

.emoji-picker {
  position: absolute;
}

@media (prefers-color-scheme: dark) {
  .sticky-top {
    background-color: #1E1E1E;
  }
  .sticky-bottom {
    background-color: #1E1E1E;
  }
  ion-item.chat {
    --background: #121212;
  }
  .chat-list-background {
    background-color: #121212;
  }
}