.ion-modal-header {
  display: flex;
  flex-direction: column-reverse;

  ion-button {
    align-self: flex-start;
    margin-top: 2rem;
    margin-right: 2rem;
  }
}

ion-modal {
  ion-button {
    margin-left: 1rem;
  }
}

.ion-persona-title {
  text-transform: capitalize;
  font-size: large;
}

.ion-modal-header {
  font-size: x-large;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
}

.ion-modal-basic-information {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
}

.ion-modal-container {
  overflow-y: auto;
}

@media only screen and (min-width: 724px) {
  .ion-modal-basic-information {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-left: 1rem;
    align-items: start;
    justify-content: start;
  }

  .ion-modal-header {
    text-align: start;
  }

}

@media only screen and (min-width: 600px) {
  .ion-custom-modal {
    --min-width: 90vw;
    --min-height: 90vh;
  }

  .ion-modal-container {
    padding-left: 2rem;
  }

  .ion-modal-container {
    overflow-y: auto;
  }
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #fbbf51;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}


.ion-picture-section {
  display: flex;
}