.large-modal {
  --min-width: 40rem;
}

.login-container {
  margin: 6rem 12rem;
}

.toast-style {
  font-size: larger;
  font-family: Satoshi-Regular, sans-serif;
}

ion-toast::part(button) {
  font-family: Satoshi-Regular, sans-serif;
}

/* ion-button.purple {
  font-family: Satoshi-Regular, sans-serif;
  margin-left: 1rem;
  --border-radius: 1.5rem;
  --background: #FBBF51;
  --background-hover: #8f50a8;
  font-weight: bold;
  --box-shadow: 3px 3px 4px 1px rgb(167, 164, 164);
  width: 14rem;
  margin-top: 2rem;
} */

.ion-invalid {
  --color: #cc0000 !important;
  border-color: red !important;
}

@media only screen and (max-width: 600px) {
  .large-modal {
    --min-width: 10rem;
  }

  .login-container {
    margin: 1rem 2rem;
  }
}

ion-toast {
  font-weight: bold;
  --background: #cc0000;
  --color: white;
  --border-style: bold;
  --button-color: white;
}