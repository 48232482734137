.ion-modal-header {
  display: flex;
  flex-direction: column-reverse;

  ion-button {
    align-self: flex-start;
    margin-right: 2rem;
  }
}

.ion-modal {
  --height: auto;
}

.ion-modal-button-group {
  display: flex;
  align-items: center;
  gap: 1;
  margin-left: -1rem;
}

.ion-persona-title {
  text-transform: capitalize;
  font-size: large;
  color: #fbbf51;
}

.ion-modal-header {
  font-size: x-large;
  font-weight: bold;
  text-transform: capitalize;
}

.ion-modal-basic-information {
  margin-left: 1rem;
}

.ion-modal-container {
  overflow-y: auto;
}

.modal-connecting-header {
  margin-top: 2rem;
}

.modal-connecting-subheader {
  margin-top: 0.5rem;
}

@media only screen and (min-width: 600px) {

 
}

@media only screen and (max-width: 600px) {
  .ion-modal-button-group {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1;
    margin-left: -1rem;
  }
}


@media only screen and (min-width: 600px) {
  .ion-custom-modal {
    min-width: 90vw;
    min-height: 90vh;
  }

  .ion-modal {
    --min-width: 90vw;
    --min-height: 90vh;
    min-width: 90vw;
    min-height: 90vh;
  }

  .ion-modal-container {
    padding-left: 2rem;
  }

  .ion-modal-container {
    overflow-y: auto;
  }
}