@keyframes glow {
  0% {
    box-shadow: 0 0 5px #ff7b00, 0 0 10px #ff7b00, 0 0 15px #ff7b00, 0 0 20px #ff7b00;
  }
  
  50% {
    box-shadow: 0 0 10px #ff7b00, 0 0 20px #ff7b00, 0 0 30px #ff7b00, 0 0 40px #ff7b00;
  }
  
  100% {
    box-shadow: 0 0 5px #ff7b00, 0 0 10px #ff7b00, 0 0 15px #ff7b00, 0 0 20px #ff7b00;
}
}

.glowing-border {
    animation: glow 2s ease-in-out infinite;
}