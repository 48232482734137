:root[mode=ios] .satoshi,
:root[mode=md] .satoshi {
  --ion-font-family: 'Satoshi-Regular', sans-serif !important;
  font-family: 'Satoshi-Regular', sans-serif !important;
}

:root[mode=ios] .manrope-bold,
:root[mode=md] .manrope-bold {
  --ion-font-family: 'Manrope', sans-serif !important;
  font-family: 'Manrope', sans-serif !important;
  font-weight: bold;
}

@font-face {
  font-family: 'Satoshi-Regular';
  src: url(./fonts/Satoshi-Regular.woff);
}

@font-face {
  font-family: 'Manrope';
  src: url(./fonts/Manrope-Regular.woff);
}