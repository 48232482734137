.ion-close-button {
    margin-top: 1rem;
}

.modal-header {
    text-align: center;
}

.modal-content {
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.avatar {
    font-family: "Satoshi", sans-serif;
    font-weight: bold;
    cursor: pointer;
}

.avatar:hover {
    opacity: 0.8;
}

.file-upload-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.file-upload-input {
    display: none;
}

.file-upload-label {
    background-color: #9c9c9c;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: normal;
    transition: background-color 0.3s;
    font-family: "Satoshi", sans-serif;
}

.confirm-button {
    width: 11rem;
    margin-right: 0.9rem;
}

.file-upload-label:hover {
    background-color: #6b6b6b;
}