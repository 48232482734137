.avatar-half {
  text-align: start;
}

.dashboard-title {
  text-transform: capitalize;
  font-weight: bold;
}

.avatar:hover {
  cursor: pointer;
  opacity: 0.8; 
}


/* xs */
@media only screen and (max-width: 600px) {
  .avatar-half {
    text-align: center;
    padding-top: 1.2rem;
  }
  .dashboard-title {
    margin-left: 2rem;
  }
}